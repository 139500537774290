import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMonths, parse } from "date-fns";
import mediator from "@tvg/mediator";
import { getIsLogged } from "@urp/store-selectors";
import VideoPlayer from "@urp/video-player";
import { useGetRacesWithBetsByTrack } from "@urp/lib-racetracks/src/hooks/useGetRacesWithBetsByTrack";
import { TRACKS_FILTER_OPTIONS } from "@urp/lib-racetracks/src/constants";
import {
  setCurrentReplayVideo,
  toggleOnlyFavorites
} from "@urp/lib-racetracks/src/redux/actions/tracks";
import {
  getCurrentReplayVideoFileName,
  getCurrentVideoTitle,
  getOnlyFavoritesTracks
} from "@urp/lib-racetracks/src/redux/selectors";
import { openMybetsPastPerformance } from "@tvg/sh-lib-my-bets/redux/actions";
import { MyBetsPastPerformanceTab } from "@tvg/sh-lib-my-bets/utils/types";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Checkbox } from "@tvg/design-system/web";
import { isFDR } from "@tvg/utils/generalUtils";
import { getSession } from "@urp/unified-modules/src/utils/account";

import { PastResultsSearchOptionKey as SearchOptionKey } from "../../types";
import SearchOptions from "../SearchOptions";
import ResultsSearchForm from "../ResultsSearchForm";
import PastResultsDateSearch from "../PastResultsDateSearch";
import PastResultsTrackSearch from "../PastResultsTrackSearch";
import PastResultsHorseSearch from "../PastResultsHorseSearch";
import {
  pastResultsInitialConfig as initialConfig,
  INTERNAL_DATE_FORMAT
} from "../../constants";
import { FieldConfig } from "../ResultsSearchForm/types";
import SpoilerToggle from "../SpoilerToggle";
import {
  Container,
  ContainerHeader,
  Body,
  SectionContainer,
  SearchOptionsContainer,
  CheckboxContainer,
  SelectorsContainer
} from "./styled-components";
import { MyBetsClickProps } from "./types";

interface PastResultsProps {
  qaLabel?: string;
}

const PastResults: FC<PastResultsProps> = ({ qaLabel = "tracksResults" }) => {
  const isLogged = useSelector(getIsLogged);
  const isMobile = useMediaQuery(breakpoints.desktop.max.sm);
  const onlyFavorites = useSelector(getOnlyFavoritesTracks) && isLogged;
  const videoFileName = useSelector(getCurrentReplayVideoFileName);
  const videoTitle = useSelector(getCurrentVideoTitle);
  const dispatch = useDispatch();

  const [searchOption, setSearchOption] = useState<SearchOptionKey>(
    SearchOptionKey.DATE
  );
  const [fieldsConfig, setFieldsConfig] = useState<FieldConfig[]>([]);

  const { fetchRacesWithBets, races: racesWithBets } =
    useGetRacesWithBetsByTrack();

  useEffect(
    () => () => {
      dispatch(setCurrentReplayVideo(null));
    },
    []
  );

  useEffect(() => {
    const dateFilter = fieldsConfig.find(({ key }) => key === "date")?.value;
    const trackCode = fieldsConfig.find(({ key }) => key === "track")?.value;

    const parsedDate = parse(
      dateFilter as string,
      INTERNAL_DATE_FORMAT,
      new Date()
    );
    const isDateOlderThan17Months =
      differenceInMonths(new Date(), parsedDate) > 17;

    if (
      trackCode === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value ||
      isDateOlderThan17Months
    )
      return;

    if (isLogged && dateFilter && trackCode) {
      fetchRacesWithBets({
        trackCode: trackCode as string,
        endDate: dateFilter as string,
        startDate: dateFilter as string
      });
    }
  }, [fieldsConfig, isLogged]);

  const handleFieldsConfigChange = useCallback(
    (newFieldsConfig: FieldConfig[]) => {
      setFieldsConfig(newFieldsConfig);
    },
    []
  );

  const handleMyBetsClick = ({
    trackCode,
    raceNumber,
    trackName
  }: MyBetsClickProps) => {
    const dateFilter = fieldsConfig.find(({ key }) => key === "date")?.value;
    const hasBackButton = false;

    dispatch(
      openMybetsPastPerformance(
        {
          trackCode,
          raceNumber: Number(raceNumber),
          raceDate: dateFilter as string
        },
        `${trackName} R${raceNumber} - Replay`,
        MyBetsPastPerformanceTab.BETS,
        hasBackButton
      )
    );
  };

  const handleCheckboxChange = (selectedValues: Array<string>) => {
    const newOnlyFavorites = selectedValues.includes("only_favorites");
    if (!isLogged) {
      if (isFDR()) {
        getSession("Normal", () => {
          dispatch(toggleOnlyFavorites(newOnlyFavorites));
        });
      } else {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            callback: (error: Error | null, success: Object) => {
              if (!error && success) {
                dispatch(toggleOnlyFavorites(newOnlyFavorites));
              }
            },
            triggerAction: "tracks_results_favorites_tab"
          }
        });
      }
    } else {
      dispatch(toggleOnlyFavorites(newOnlyFavorites));
    }
  };

  const renderSearchContent = () => {
    const commonProps = {
      onFieldsConfigChange: handleFieldsConfigChange,
      onMyBetsClick: handleMyBetsClick,
      racesWithBets,
      onlyFavorites,
      isMobile
    };

    switch (searchOption) {
      case SearchOptionKey.DATE:
        return (
          <PastResultsDateSearch
            qaLabel={`${qaLabel}-dateSearch`}
            {...commonProps}
          />
        );
      case SearchOptionKey.TRACK:
        return (
          <PastResultsTrackSearch
            qaLabel={`${qaLabel}-trackSearch`}
            {...commonProps}
          />
        );
      case SearchOptionKey.HORSE:
        return (
          <PastResultsHorseSearch
            qaLabel={`${qaLabel}-horseSearch`}
            onFieldsConfigChange={handleFieldsConfigChange}
            isMobile={isMobile}
          />
        );
      default:
        return null;
    }
  };

  const handleSelectedChange = (newValue: string) => {
    if (searchOption === newValue) return;

    setFieldsConfig([]);
    setSearchOption(newValue as SearchOptionKey);
  };

  const renderOnlyFavorite = () =>
    [SearchOptionKey.DATE, SearchOptionKey.TRACK].includes(searchOption) && (
      <CheckboxContainer>
        <Checkbox.Group
          qaLabel="onlyFavoritesCheckbox"
          selectedValues={onlyFavorites ? ["only_favorites"] : []}
          onChange={handleCheckboxChange}
        >
          <Checkbox value="only_favorites" label="Only favorites" />
        </Checkbox.Group>
      </CheckboxContainer>
    );

  const showVideoPlayer = isLogged && isMobile && videoFileName;

  return (
    <>
      <Container>
        <ContainerHeader>
          <SearchOptionsContainer>
            <SearchOptions
              label={initialConfig.label}
              options={initialConfig.options}
              selectedOption={searchOption}
              onSelectedChange={handleSelectedChange}
              qaLabel={`${qaLabel}-searchOptions`}
            />
            {!isMobile && renderOnlyFavorite()}
          </SearchOptionsContainer>
          {fieldsConfig.length > 0 && (
            <ResultsSearchForm
              fieldsConfig={fieldsConfig}
              qaLabel={`${qaLabel}-searchForm`}
            />
          )}
          <SelectorsContainer>
            {isMobile && (
              <>
                {renderOnlyFavorite()}
                <SpoilerToggle qaLabel={qaLabel} />
              </>
            )}
          </SelectorsContainer>
        </ContainerHeader>
        <Body>
          <SectionContainer isMobile={isMobile}>
            {renderSearchContent()}
          </SectionContainer>
        </Body>
      </Container>
      {showVideoPlayer && (
        <VideoPlayer
          qaLabel="replay-video-tracks-results-mobile"
          isAutoPlay
          title={videoTitle}
          width={window.innerWidth}
          height={300}
          replayFile={videoFileName}
          onClose={() => dispatch(setCurrentReplayVideo(null))}
          openInlineMobile={isMobile}
        />
      )}
    </>
  );
};

export default PastResults;
