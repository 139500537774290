import React, { memo } from "react";
import { Paragraph, IconWithBackground, Button } from "@tvg/design-system/web";
import { useNavigate } from "react-router";
import { Container, Row } from "./styled-components";

const EmptyTrackRaces = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <IconWithBackground mainIcon="horse" badgeIcon="info" />
        <Paragraph qaLabel="" fontFamily="bold">
          No races available today
        </Paragraph>
      </Row>
      <Button
        variant="tertiary"
        qaLabel="today-races-button"
        iconPosition="end"
        icon="arrowRight"
        onClick={() => navigate("/tracks")}
      >
        Today&apos;s races
      </Button>
    </Container>
  );
};

export default memo(EmptyTrackRaces);
