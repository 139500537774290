import React, { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import { isEqual } from "date-fns";
import { getDateRace } from "@urp/store-selectors";
import { useResultsSearchByDate, usePastRaces } from "@urp/lib-racetracks";
import { TRACKS_FILTER_OPTIONS } from "@urp/lib-racetracks/src/constants";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { RaceWithBets } from "@urp/lib-racetracks/src/hooks/useGetRacesWithBetsByTrack";
import { useResultedRaces } from "@urp/lib-racetracks/src/hooks/useResultedRaces";
import { PastResultsSearchOptionKey as SearchOptionKey } from "../../types";
import { FieldConfig } from "../ResultsSearchForm/types";
import { getFieldType } from "../../utils";
import { pastResultsInitialConfig as initialConfig } from "../../constants";
import FavoritesNotFound from "../../emptyCases/FavoritesNotFound";
import TracksAZ from "../TracksAZ";
import PastResultsByTrack from "../PastResultsByTrack";
import { MyBetsClickProps } from "../PastResults/types";

interface PastResultsDateSearchProps {
  qaLabel: string;
  onlyFavorites?: boolean;
  onFieldsConfigChange: (fieldsConfig: FieldConfig[]) => void;
  onMyBetsClick?: UnaryFn<MyBetsClickProps, void>;
  racesWithBets?: RaceWithBets;
  isMobile?: boolean;
}

const PastResultsDateSearch: FC<PastResultsDateSearchProps> = ({
  qaLabel,
  onlyFavorites = false,
  onFieldsConfigChange,
  onMyBetsClick = noop,
  racesWithBets,
  isMobile = false
}) => {
  const {
    selectedDate,
    selectedTrack,
    tracksOptions,
    selectedTrackDetails,
    setSelectedDate,
    setSelectedTrack
  } = useResultsSearchByDate(onlyFavorites);

  const currentRaceDate = useSelector(getDateRace);
  const isTodayResults =
    !!selectedDate &&
    isEqual(new Date(selectedDate), new Date(currentRaceDate));
  const formattedDate = formatDateToMMDDYYYY(selectedDate || new Date());

  const { isLoading: isPastRacesLoading, pastRaces } = usePastRaces({
    enabled: !isTodayResults,
    date: selectedDate,
    trackCode: selectedTrack
  });

  const { loading: todayRacesLoading, races: todayRaces } = useResultedRaces({
    isEnabled: isTodayResults,
    trackAbbr: selectedTrack,
    sortPostTime: "DESC"
  });

  const workingRaces = isTodayResults ? todayRaces : pastRaces;
  const isLoading = isTodayResults ? todayRacesLoading : isPastRacesLoading;

  useEffect(() => {
    const dateConfig = initialConfig.options.find(
      (option) => option.key === SearchOptionKey.DATE
    );

    const fieldsConfig =
      dateConfig?.fields.map((field) => ({
        ...field,
        value:
          field.key === SearchOptionKey.DATE ? selectedDate : selectedTrack,
        onChange:
          field.key === SearchOptionKey.DATE
            ? setSelectedDate
            : setSelectedTrack,
        options:
          field.key === SearchOptionKey.TRACK ? tracksOptions : undefined,
        type: getFieldType(field.type, isMobile)
      })) || [];

    onFieldsConfigChange(fieldsConfig);
  }, [
    selectedDate,
    selectedTrack,
    setSelectedDate,
    setSelectedTrack,
    tracksOptions,
    onFieldsConfigChange
  ]);

  const memoizedTrackPastRaces = useMemo(
    () =>
      ![
        TRACKS_FILTER_OPTIONS.ALL_TRACKS.value,
        TRACKS_FILTER_OPTIONS.NO_TRACKS.value
      ].includes(selectedTrack) ? (
        <PastResultsByTrack
          trackCode={selectedTrack}
          name={selectedTrackDetails?.name || ""}
          country={selectedTrackDetails?.location.country || ""}
          pastRaces={workingRaces}
          date={formattedDate}
          isLoading={isLoading}
          qaLabel={`${qaLabel}-trackPastRaces`}
          onMyBetsClick={onMyBetsClick}
          racesWithBets={racesWithBets}
        />
      ) : null,
    [
      selectedTrack,
      selectedTrackDetails,
      workingRaces,
      isLoading,
      formattedDate,
      qaLabel,
      racesWithBets
    ]
  );

  if (selectedTrack === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value) {
    return (
      <TracksAZ
        isResults
        isOnlyFavorites={onlyFavorites}
        subTitle={`Showing results All Tracks on ${formattedDate}`}
        onMyBetsClick={onMyBetsClick}
        dateFilter={selectedDate}
      />
    );
  }
  if (
    selectedTrack === TRACKS_FILTER_OPTIONS.NO_TRACKS.value &&
    onlyFavorites
  ) {
    return <FavoritesNotFound />;
  }

  return memoizedTrackPastRaces;
};

export default PastResultsDateSearch;
