import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const FieldsContainer = styled.div`
  display: flex;
  gap: var(--fd-space-space-5);

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      flex-direction: column;
      width: 100%;
    }
  `}

  @media screen and ${breakpoints.tablet.max.sm} {
    gap: var(--fd-space-space-2);
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  gap: var(--fd-space-space-3);

  & .DayPickerInput {
    width: 100%;
  }
`;

export const FieldLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.subtle};
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
  `}

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      display: none;
    }
  `}
`;
