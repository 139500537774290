import styled from "styled-components";

export const ContentPlaceholder = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Container = styled.div``;

export const PortalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--fd-colors-core-blue-l-5);
`;
