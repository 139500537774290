import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 4px 0;
  border-bottom: 1px solid var(--fd-colors-border-subtle, #c6d3e1);
  background: var(--fd-colors-background-surface, #fff);
  z-index: 1;
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 100px);
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--fd-colors-border-subtle, #c6d3e1);
`;
