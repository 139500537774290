import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import {
  getIsLogged,
  getOptedInPromos,
  getCPPPromos
} from "@urp/store-selectors";
import useRaceAmplitudeEvent from "@urp/tracks-builder/src/hooks/useRaceAmplitudeEvent";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import type { Race } from "@tvg/ts-types/Race";
import type { Track } from "@tvg/ts-types/Track";
import {
  hasPromoAvailable,
  isUserOptedInPromo,
  hasRacePromoCPP,
  hasRacePromoCPPOptedIn
} from "@urp/lib-racetracks/src/utils/races";
import { TABS } from "@urp/lib-racetracks/src/types";
import { getActiveBets } from "@urp/lib-racetracks/src/redux/selectors";
import buildRaceUrl from "@tvg/formatter/url";
import { RaceCell } from "@tvg/design-system/web/components/RaceCell";
import { Element } from "./styled-components";

const renderRacesList = (races: Race[], qaLabel: string) => {
  const isUserLogged = useSelector(getIsLogged);
  const optedInPromos = useSelector(getOptedInPromos);
  const activeBets = useSelector(getActiveBets);
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const cppPromos = useSelector(getCPPPromos);
  const { trackRaceAmplitudeEvent } = useRaceAmplitudeEvent(
    isDesktop ? TABS.TODAY : undefined
  );

  return races.map((race: Race) => {
    const availableRacePromos = hasPromoAvailable(race);
    const isOptedIn =
      isUserLogged && isUserOptedInPromo(optedInPromos, availableRacePromos);

    const hasCPPPromoAvailable = hasRacePromoCPP(cppPromos, race);
    const isCPPPromosOptedIn = hasRacePromoCPPOptedIn(
      cppPromos,
      race,
      isUserLogged
    );
    const raceUrl = buildRaceUrl(
      race.track.code,
      race.track.name,
      +race.number
    );
    return (
      <Element key={race.id}>
        <RaceCell.Details
          race={race}
          to={raceUrl}
          numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
          qaLabel={`${qaLabel}-${race.id}`}
          isOptedIn={isOptedIn || isCPPPromosOptedIn}
          hasPromosAvailable={
            (!isOptedIn && !!availableRacePromos.length) ||
            (!isCPPPromosOptedIn && hasCPPPromoAvailable)
          }
          onClick={() =>
            trackRaceAmplitudeEvent(
              race,
              race.track as Track,
              Module.UPCOMING_RACES
            )
          }
        />
      </Element>
    );
  });
};

export default renderRacesList;
