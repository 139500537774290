import color from "@fdr/static-ui/ColorPalette";
import styled, { css, keyframes } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
  width: 100%;

  & > div:first-child {
    box-shadow: none;
  }

  /* stylelint-disable-next-line media-query-no-invalid */
  @media screen and ${breakpoints.tablet.max.md} {
    border-bottom: 1px solid ${({ theme }) => theme.colorTokens.border.default};
  }
`;

export const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white["900"]};
  display: flex;
  gap: ${({ theme }) => theme.space["space-3"]};
  overflow: auto;
  padding: 0 8px 12px;
  scroll-snap-type: x mandatory;
  scroll-padding-left: ${({ theme }) => theme.space["space-2"]};
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MaskContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

export const Element = styled.div`
  scroll-snap-align: start;
`;

const Box = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.subtle}`};

  /* stylelint-disable-next-line media-query-no-invalid */
  @media screen and ${breakpoints.desktop.min.sm} {
    padding-top: 24px;
  }
`;

export const NoUpcomingRacesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HintContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    margin-top: ${theme.space["space-7"]};
    padding: ${theme.space["space-4"]} 0;
  `}
  gap: 10px;
`;

export const IconContainer = styled.div`
  & > div > svg {
    width: 40px;
    height: 40px;
  }
`;

export const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

export const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export const EmptyMessageContainer = styled.div`
  /* stylelint-disable-next-line media-query-no-invalid */
  @media screen and ${breakpoints.desktop.min.sm} {
    display: flex;
    justify-content: center;
    padding: var(--fd-space-space-5);
  }
`;
