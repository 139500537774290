import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const SpoilerToggleContainer = styled.div`
  display: flex;
  align-items: center;

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      margin-left: auto;
    }
  `}
`;
