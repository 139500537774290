import React, { FC, useState } from "react";
import { usePastRaces } from "@urp/lib-racetracks";
import { PageHeader } from "@tvg/design-system";
import { CollapsibleToggle } from "../../PastResultsByYear/types";
import { getRacesGroupedByYear } from "../../PastResultsByYear/utils";
import CollapsibleByYear from "../../PastResultsByYear/CollapsibleByYear";
import TracksAZLoading from "../../../loadingMasks/TracksAZLoading";
import { Container } from "./styled-components";

interface HorsePastResultsProps {
  horseName: string;
  runnerId: string | number;
  onBackButtonClick: () => void;
}

const HorsePastResults: FC<HorsePastResultsProps> = ({
  horseName,
  runnerId,
  onBackButtonClick
}) => {
  const [isOpen, setIsOpen] = useState<CollapsibleToggle>({});
  const { isLoading, pastRaces } = usePastRaces({
    runnerId: runnerId ? `${runnerId}` : undefined
  });
  const racesGroupedByYear = getRacesGroupedByYear(pastRaces);

  const handleOnClickCollapsible = (year: string) => {
    setIsOpen((prev) => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  const renderRaces = () => {
    if (isLoading) {
      return <TracksAZLoading />;
    }

    if (pastRaces.length === 0) {
      return <div>No results yet!</div>;
    }

    return Object.entries(racesGroupedByYear)
      .sort((a, b) => (+a[0] < +b[0] ? 1 : -1))
      .map(([year, races]) => (
        <CollapsibleByYear
          key={year}
          races={races}
          year={year}
          isOpen={isOpen}
          handleOnClick={handleOnClickCollapsible}
        />
      ));
  };

  return (
    <Container>
      <PageHeader
        title={horseName}
        onBack={onBackButtonClick}
        titlePosition="center"
        hasBorderBottom
      />
      {renderRaces()}
    </Container>
  );
};

export default HorsePastResults;
