import React, { FC } from "react";
import { useSelector } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  IconWithBackground,
  DialogMessage,
  Button
} from "@tvg/design-system/web";
import { TABS, useRaceTracksFilter } from "@urp/lib-racetracks";
import {
  Container,
  IconContainer,
  MessageContainer,
  Text,
  TextContainer,
  Title
} from "./styled-component";
import { getTracksEmptyMessageInfo } from "../../redux/selectors";

interface EmptyMessageProps {
  currentTab?: string;
  isGlobal?: boolean;
  isTracksSearchActive?: boolean;
}

const EmptyMessage: FC<EmptyMessageProps> = ({
  currentTab = "",
  isGlobal = false,
  isTracksSearchActive = false
}) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const { handleClearFilters } = useRaceTracksFilter();

  const { bySearch, byFilters, noRaces } = useSelector(
    getTracksEmptyMessageInfo
  );
  const getTitle = () => {
    if (currentTab === TABS.RESULTS) {
      return noRaces.title;
    }

    return currentTab === TABS.TODAY
      ? bySearch?.tracks.title
      : bySearch?.results.title;
  };

  const getDescription = () => {
    if (currentTab === TABS.RESULTS) {
      return noRaces.hint;
    }

    return currentTab === TABS.TODAY
      ? bySearch?.tracks.hint
      : bySearch?.results.hint;
  };

  const handleCleanFilter = () => {
    handleClearFilters();
  };

  return (
    <Container>
      {isGlobal ? (
        <DialogMessage
          illustration={
            <IconWithBackground mainIcon="tracks" badgeIcon="info" />
          }
          title={getTitle()}
          description={getDescription()}
          padding="space-7"
        />
      ) : isMobile ? (
        <MessageContainer>
          <IconContainer>
            <IconWithBackground
              mainIcon="filters"
              badgeIcon="info"
              override={{
                mainIcon: {
                  size: "m"
                }
              }}
            />
          </IconContainer>
          <TextContainer>
            <Title data-qa-label="empty-message-title">{byFilters.title}</Title>
            <Text data-qa-label="empty-message-hint">{byFilters.hint}</Text>
          </TextContainer>
        </MessageContainer>
      ) : (
        <DialogMessage
          illustration={
            <IconWithBackground mainIcon="filters" badgeIcon="info" />
          }
          title={byFilters.title}
          description={byFilters.hint}
          padding="space-7"
        />
      )}
      {!isTracksSearchActive && (
        <Button
          qaLabel="clear-filter-btn"
          variant="primary"
          onClick={handleCleanFilter}
          isStretched
        >
          Clear filters
        </Button>
      )}
    </Container>
  );
};

export default EmptyMessage;
