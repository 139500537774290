import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useResultsSearchByHorse,
  setFilteredHorseNames
} from "@urp/lib-racetracks";
import { getSearchedHorseNames } from "@urp/lib-racetracks/src/redux/selectors";
import { createPortal } from "react-dom";
import { FieldConfig } from "../ResultsSearchForm/types";
import HorseNamesList from "./HorseNamesList";
import { useFieldsConfigChange } from "../../hooks/useFieldsConfigChange";
import HorseNotFound from "../../emptyCases/HorseNotFound";
import {
  ContentPlaceholder,
  Container,
  PortalContainer
} from "./styled-components";
import HorsePastResults from "./HorsePastResults";

interface PastResultsHorseMobileProps {
  qaLabel: string;
  onFieldsConfigChange: (fieldsConfig: FieldConfig[]) => void;
}

const PastResultsHorseMobile: FC<PastResultsHorseMobileProps> = ({
  onFieldsConfigChange,
  qaLabel
}) => {
  const [showRunnerResults, setShowRunnerResults] = useState(false);
  const [runnerId, setRunnerId] = useState<string | number>("");
  const [horseName, setHorseName] = useState<string>("");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const {
    searchText,
    setSearchText,
    setSelectedHorseOption,
    horsesOptions,
    isLoading: isHorseSearchLoading
  } = useResultsSearchByHorse();

  useFieldsConfigChange(
    searchText,
    setSearchText,
    setSelectedHorseOption,
    horsesOptions,
    isHorseSearchLoading,
    onFieldsConfigChange,
    setFilteredHorseNames
  );
  const horseNames = useSelector(getSearchedHorseNames);

  const notFoundHorses =
    searchText.length > 3 &&
    !isHorseSearchLoading &&
    (horseNames.length === 0 ||
      !horseNames.some((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      ));

  const noUserSearch =
    (searchText.length === 0 && horseNames.length === 0) ||
    isHorseSearchLoading;

  const handleOnHorseOptionClick = (name: string, id: string | number) => {
    setShowRunnerResults(!showRunnerResults);
    setHorseName(name);
    setRunnerId(id);
    window.scrollTo(0, 0);
  };

  const handleOnBackButtonClick = () => {
    setShowRunnerResults(false);
  };

  const memoizedHorseNamesList = useMemo(
    () =>
      !showRunnerResults ? (
        <HorseNamesList
          horses={horseNames}
          searchedHorse={searchText}
          onClickHorseOption={handleOnHorseOptionClick}
          qaLabel={qaLabel}
        />
      ) : null,
    [horseNames, searchText, qaLabel, showRunnerResults]
  );

  if (notFoundHorses) {
    return <HorseNotFound name={searchText} />;
  }

  if (noUserSearch) {
    return <ContentPlaceholder />;
  }

  return (
    <Container ref={containerRef}>
      {showRunnerResults &&
        createPortal(
          <PortalContainer>
            <HorsePastResults
              horseName={horseName}
              runnerId={runnerId}
              onBackButtonClick={handleOnBackButtonClick}
            />
          </PortalContainer>,
          containerRef.current as Element
        )}
      {memoizedHorseNamesList}
    </Container>
  );
};

export default PastResultsHorseMobile;
