import React, { FC } from "react";
import { LinkItem } from "@tvg/design-system";
import { Container, StyledParagraph } from "./styled-components";
import { HorseNamesListProps } from "./types";

const HorseNamesList: FC<HorseNamesListProps> = ({
  horses,
  searchedHorse,
  onClickHorseOption,
  qaLabel
}) => (
  <Container>
    {horses.map(({ value, label }) => {
      const horseName = label.toLowerCase();
      const searchedText = searchedHorse.toLowerCase();
      const firstPos = horseName.indexOf(searchedText);
      const termLength = searchedText.length;

      const styledHorseName = (
        <StyledParagraph>
          <span>{label.substring(0, firstPos)}</span>
          <strong>{label.substring(firstPos, firstPos + termLength)}</strong>
          <span>{label.substring(firstPos + termLength)}</span>
        </StyledParagraph>
      );

      return (
        <LinkItem
          key={value}
          title={{ render: styledHorseName }}
          hasDividerLine
          hasArrowRight
          qaLabel={`${qaLabel}-${label}-item`}
          onPress={() => onClickHorseOption(label, value)}
        />
      );
    })}
  </Container>
);
export default HorseNamesList;
