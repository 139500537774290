import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Countries } from "@tvg/design-system";
import { TABS, useFavoriteWithRedux } from "@urp/lib-racetracks";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Flag } from "@tvg/design-system/web/";
import { getIsResultsCardStacked } from "../../redux/selectors";
import FavoriteIcon from "../FavoriteIcon";
import RaceResultCard from "../RaceResultCard";
import SpoilerToggle from "../SpoilerToggle";
import EmptyMessage from "../EmptyMessage";
import { PastResultsByTrackProps } from "./types";
import {
  Block,
  BodyHeader,
  RaceResultsContainer,
  TitleWrapper,
  StyledHeader,
  StyledParagraph,
  ToggleContainer,
  EmptyContainer
} from "./styled-components";
// TODO: Add LoadingMask when we have pastRaces with runners data
// import LoadingMask from "./LoadingMask";

const PastResultsByTrack: FC<PastResultsByTrackProps> = ({
  trackCode,
  name,
  country,
  date,
  pastRaces,
  isLoading,
  qaLabel,
  onMyBetsClick,
  racesWithBets
}) => {
  const dispatch = useDispatch();
  const isResultsCardStacked = useSelector(getIsResultsCardStacked);
  const { isFavorite: getIsFavorite, toggleFavorite } = useFavoriteWithRedux(
    Module.TRACKS_RESULTS
  );
  const isMobile = useMediaQuery(breakpoints.desktop.max.sm);
  const isFavorite = getIsFavorite(trackCode);

  const renderRaces = () => {
    // TODO: Add LoadingMask when we have pastRaces with runners data
    // if (isLoading) {
    //   return <LoadingMask qaLabel={`${qaLabel}-LoadingMask`} />;
    // }
    if (isLoading) {
      // TODO: Add loading mask
      return <div>Loading...</div>;
    }

    return (
      <>
        {pastRaces.map((race) => (
          <RaceResultCard
            key={race.track.code + race.number}
            dispatch={dispatch}
            race={race}
            isGrouped={isMobile && !isResultsCardStacked}
            betsCounter={
              (racesWithBets && (racesWithBets[race.number] as number)) ?? 0
            }
            onMyBetsClick={() =>
              onMyBetsClick?.({
                trackCode: race.track.code,
                raceNumber: race.number,
                trackName: race.track.name
              })
            }
          />
        ))}
      </>
    );
  };

  return (
    <>
      <BodyHeader isMobile={isMobile}>
        <Block>
          <TitleWrapper>
            <Flag
              country={country as Countries}
              size="m"
              qaLabel={`${qaLabel}-track-country`}
            />
            <StyledHeader tag="h2" qaLabel={`${qaLabel}-track-name`}>
              {name}
            </StyledHeader>
            <FavoriteIcon
              isFavorite={isFavorite}
              toggleFavorite={() => toggleFavorite(trackCode, name)}
              qaLabel={qaLabel}
            />
          </TitleWrapper>
          <StyledParagraph qaLabel={`${qaLabel}-results-counter`}>
            {`Showing results for ${pastRaces.length} races on ${date}`}
          </StyledParagraph>
        </Block>
        <ToggleContainer>
          {!isMobile && <SpoilerToggle qaLabel={`${qaLabel}-SpoilerToggle`} />}
        </ToggleContainer>
      </BodyHeader>
      {!isLoading && pastRaces.length === 0 ? (
        <EmptyContainer>
          <EmptyMessage
            isGlobal
            isTracksSearchActive
            currentTab={TABS.RESULTS}
          />
        </EmptyContainer>
      ) : (
        <RaceResultsContainer isResultsCardStacked={isResultsCardStacked}>
          {renderRaces()}
        </RaceResultsContainer>
      )}
    </>
  );
};

export default PastResultsByTrack;
