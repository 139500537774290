import styled, { css } from "styled-components";
import { Header, Paragraph } from "@tvg/design-system/web/";
import { breakpoints } from "@tvg/design-system";

export const Block = styled.section`
  width: 100%;
  position: relative;
  background-color: var(--fd-colors-background-surface);

  :empty {
    display: none;
  }
`;

export const BodyHeader = styled(Block)<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  padding: var(--fd-space-space-5);
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: var(--fd-space-space-3);
    `}
`;

export const RaceResultsContainer = styled.div<{
  isResultsCardStacked: boolean;
}>`
  background-color: var(--fd-colors-background-surface);
  gap: var(--fd-space-space-4);
  padding: var(--fd-space-space-4);
  display: flex;
  ${({ isResultsCardStacked }) =>
    isResultsCardStacked
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
          overflow: scroll hidden;
          scroll-behavior: smooth;
          scroll-snap-type: x mandatory;
          scroll-padding-left: var(--fd-space-space-4);

          & > span {
            scroll-snap-align: start;
          }
        `};

  @media only screen and ${breakpoints.tablet.min.sm} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--fd-space-space-1);
`;

export const StyledHeader = styled(Header)`
  color: var(--fd-colors-content-strong);
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: var(--fd-space-space05, 2px);
  color: var(--fd-colors-content-subtle);
`;

export const StyledSummary = styled(Header)`
  color: var(--fd-colors-content-strong);
  font-size: var(--fd-font-sizes-font-size-040);
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--fd-colors-background-surface);
`;
