import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  width: 100%;
`;

export const Block = styled.section`
  width: 100%;
  background-color: var(--fd-colors-background-surface);

  :empty {
    display: none;
  }
`;

export const ContainerHeader = styled(Block)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-default);
  padding: var(--fd-space-space-5);
  min-height: 87px;

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      border-bottom: none;
      padding: var(--fd-space-space-4);
    }
  `}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 65% 33%;
  gap: var(--fd-space-space-5);
  background-color: var(--fd-colors-background-base);
  padding: var(--fd-space-space-4);

  ${css`
    @media screen and ${breakpoints.tablet.max.sm} {
      padding: 0;
    }
  `}
`;

export const SectionContainer = styled.section<{
  isMobile?: boolean;
}>`
  border-radius: ${({ theme }) => theme.radii.s};
  overflow: hidden;
  ${({ isMobile }) =>
    isMobile &&
    css`
      min-height: 100vh;
    `}
`;

export const SearchOptionsContainer = styled.div`
  display: flex;
  white-space: nowrap;

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      flex-direction: column;
      margin-bottom: var(--fd-space-space-2);
    }
  `}
`;

export const SelectorsContainer = styled.div`
  display: flex;
  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      width: 100%;
      justify-content: space-between;
      margin-top: var(--fd-space-space-4);
    }
  `}
`;

export const CheckboxContainer = styled.div`
  margin: 16px;
  align-content: center;

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      margin: 0;
    }
  `}
`;
