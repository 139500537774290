import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useRaceTracksSearch } from "@urp/lib-racetracks";
import { Icon, useTheme } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";
import { getRaceTracksIsSearchActive } from "@urp/lib-racetracks/src/redux/selectors";
import {
  SearchHeaderContainer,
  CustomSearchContainer,
  CustomSearchIcon,
  CustomSearchInput,
  CustomClearButton,
  CancelSearchContainer
} from "./styled-components";
import { Props } from "./types";
import TracksSearchMask from "./TracksSearchMask";

const RaceTracksSearch = ({
  isDesktop,
  qaLabel = "raceTracksSearch",
  analyticsEnrichment,
  loading = false
}: Props) => {
  const wasActiveRef = useRef<boolean>(false);
  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);

  const {
    searchInputRef,
    isSearchActive,
    tracksSearchMsg,
    searchQuery,
    onSearchQueryChange,
    onCancelSearch,
    onClearSearch,
    onFocusSearch
  } = useRaceTracksSearch();

  const theme = useTheme();

  useEffect(() => {
    if (isTracksSearchActive) {
      wasActiveRef.current = true;
    }

    if (wasActiveRef.current && !isTracksSearchActive) {
      wasActiveRef.current = false;
    }
  }, [isTracksSearchActive]);

  if (loading) {
    return <TracksSearchMask />;
  }

  return (
    <SearchHeaderContainer
      data-qa-label={qaLabel}
      isSearchActive={isSearchActive}
    >
      <CustomSearchContainer isSearchActive={isSearchActive}>
        <CustomSearchIcon>
          <Icon
            name="search"
            size="m"
            lineColor={
              isSearchActive
                ? theme.colorTokens.component.input.active
                : theme.colorTokens.content.default
            }
          />
        </CustomSearchIcon>
        <CustomSearchInput
          ref={searchInputRef}
          placeholder={isSearchActive ? "" : tracksSearchMsg.inputPlaceholder}
          value={searchQuery}
          onChange={(e) =>
            onSearchQueryChange(e.target.value, analyticsEnrichment)
          }
          onFocus={onFocusSearch}
          data-qa-label={`${qaLabel}-input`}
        />
        <CustomClearButton
          isVisible={!!searchQuery}
          onClick={onClearSearch}
          data-qa-label={`${qaLabel}-btn-clear`}
        >
          <Icon
            name="close"
            size="s"
            lineColor={theme.colorTokens.lhnHeader.selectedcell.border}
          />
        </CustomClearButton>
      </CustomSearchContainer>
      <CancelSearchContainer isVisible={isSearchActive}>
        <Button
          variant={isDesktop ? "secondary" : "tertiary"}
          qaLabel={`${qaLabel}-btn-cancel`}
          onClick={onCancelSearch}
          ml="space-3"
        >
          {tracksSearchMsg.buttonText}
        </Button>
      </CancelSearchContainer>
    </SearchHeaderContainer>
  );
};

export default RaceTracksSearch;
