import React from "react";
import { FieldConfig } from "../ResultsSearchForm/types";
import PastResultsHorseDesk from "./PastResultsHorseDesk";
import PastResultsHorseMobile from "./PastResultsHorseMobile";

interface PastResultsHorseSearchProps {
  qaLabel: string;
  onFieldsConfigChange: (fieldsConfig: FieldConfig[]) => void;
  isMobile: boolean;
}

const PastResultsHorseSearch: React.FC<PastResultsHorseSearchProps> = ({
  qaLabel,
  onFieldsConfigChange,
  isMobile = false
}) =>
  isMobile ? (
    <PastResultsHorseMobile
      onFieldsConfigChange={onFieldsConfigChange}
      qaLabel={qaLabel}
    />
  ) : (
    <PastResultsHorseDesk
      onFieldsConfigChange={onFieldsConfigChange}
      qaLabel={qaLabel}
    />
  );

export default PastResultsHorseSearch;
