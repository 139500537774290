import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { pastResultsInitialConfig as initialConfig } from "../constants";
import { PastResultsSearchOptionKey as SearchOptionKey } from "../types";
import { getHorseNotFoundInfo } from "../redux/selectors";
import { FieldConfig } from "../components/ResultsSearchForm/types";

export const useFieldsConfigChange = (
  searchText: string,
  setSearchText: React.Dispatch<React.SetStateAction<string>>,
  setSelectedHorseOption: (option: number) => void,
  horsesOptions: {
    value: number;
    label: string;
    title: string;
  }[],
  isHorseSearchLoading: boolean,
  onFieldsConfigChange: (fieldsConfig: FieldConfig[]) => void,
  setFilteredHorseNames: (
    items: { value: string | number; label: string }[]
  ) => void
) => {
  const { warningMessage } = useSelector(getHorseNotFoundInfo);

  useEffect(() => {
    const horseConfig = initialConfig.options.find(
      (option) => option.key === SearchOptionKey.HORSE
    );

    const fieldsConfig =
      horseConfig?.fields.map((field) => ({
        ...field,
        value: searchText,
        onChange: setSearchText,
        onSubmit: setSelectedHorseOption,
        options: horsesOptions,
        isLoading: isHorseSearchLoading,
        warningMessages: {
          minLength: warningMessage.minLength,
          notFound: warningMessage.notFound
        },
        filteredItemsAction: setFilteredHorseNames
      })) || [];

    onFieldsConfigChange(fieldsConfig);
  }, [
    searchText,
    setSearchText,
    setSelectedHorseOption,
    horsesOptions,
    isHorseSearchLoading,
    onFieldsConfigChange
  ]);
};
