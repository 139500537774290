import React, { FC, useEffect, useState } from "react";
import { Modal } from "@tvg/design-system";
import {
  Option,
  InputField,
  SortContext,
  IconButton
} from "@tvg/design-system/web";
import {
  Container,
  SearchContainer,
  ScrollableContent,
  Divider
} from "./styled-components";

const ModalSelection: FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedValue: string;
  setSelectedValue: (newValue: string) => void;
  options: {
    value: string;
    label: string;
  }[];
  title?: string;
  searchPlaceholder?: string;
  qaLabel?: string;
}> = ({
  isOpen,
  setIsOpen,
  selectedValue,
  setSelectedValue,
  options,
  title = "Select Track",
  searchPlaceholder = "Search by track",
  qaLabel = "modal-selection"
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [optionsFiltered, setOptionsFiltered] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const resetSearchAndFilter = () => {
    setSearchValue("");
    setOptionsFiltered(options);
  };

  useEffect(() => {
    if (options.length > 0) {
      resetSearchAndFilter();
    }
  }, [options]);

  const handleSelection = (newSelectedValue: string) => {
    setSelectedValue(newSelectedValue);
    setIsOpen(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value.toLowerCase();
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(newSearchValue)
    );

    setSearchValue(newSearchValue);
    setOptionsFiltered(filteredOptions);
  };

  const renderSearchInput = () => (
    <SearchContainer>
      <InputField
        margin={16}
        qaLabel={`${qaLabel}-search-input`}
        value={searchValue}
        placeholder={searchPlaceholder}
        start={
          <IconButton iconName="search" size="m" qaLabel="option-search-icon" />
        }
        onChange={handleSearch}
      />
    </SearchContainer>
  );

  const renderOptions = () => (
    <ScrollableContent>
      <SortContext.Provider
        value={{
          isDropdownOpen: false,
          selectedValue,
          onChange: handleSelection,
          layer: 1
        }}
      >
        {optionsFiltered.map((option) => (
          <React.Fragment key={option.value}>
            <Option
              label={option.label}
              value={option.value}
              qaLabel={`${qaLabel}-${option.value}`}
            />
            {selectedValue !== option.value && <Divider />}
          </React.Fragment>
        ))}
      </SortContext.Provider>
    </ScrollableContent>
  );

  return (
    <Modal
      type="fullscreen"
      title={title}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      padding={0}
      hasBorderBottom={false}
      qaLabel={qaLabel}
    >
      <Container>
        {renderSearchInput()}
        {renderOptions()}
      </Container>
    </Modal>
  );
};
export default ModalSelection;
