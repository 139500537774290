import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const TabContentContainer = styled.div<{ isResults?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.background.layer};
  `};

  ${({ isResults }) =>
    isResults &&
    css`
      @media screen and ${breakpoints.desktop.max.sm} {
        background-color: var(--fd-colors-background-base);
      }
    `}
`;
