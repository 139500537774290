import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { Icon } from "@tvg/design-system/web";
import { PastRaceResults } from "@tvg/ts-types/Race";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { getOpenTracks } from "@urp/lib-racetracks/src/redux/selectors";
import { isTrackOpen } from "@urp/lib-racetracks/src/utils/races";
import { useResultedRaces } from "@urp/lib-racetracks/src/hooks/useResultedRaces";
import Collapsible from "../Collapsible";
import CollapsibleSummary from "../CollapsibleSummary";
import { Props } from "./types";
import { LIST, RACE } from "../../utils/constants";
import { RaceListContainer, ScrollButtonFloating } from "./styled-components";
import RaceResultCard from "../RaceResultCard";
import { useRaceScroll } from "../../hooks/useRaceScroll";

const CollapsibleWrapperResults = ({
  track,
  onTrackClickHandler,
  module,
  qaLabel = "",
  racesWithBets,
  onMyBetsClick
}: Props) => {
  const dispatch = useDispatch();
  const openTracks = useSelector(getOpenTracks);
  const isOpen = isTrackOpen(openTracks, track.code);
  const initialScrollBehavior = "first";
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);

  // TODO: loading, error better handled
  const { races, loading } = useResultedRaces({
    trackAbbr: track.code,
    isEnabled: isOpen
  });

  const {
    isNextEnabled,
    isPrevEnabled,
    containerRef: racesRef,
    handleScroll
  } = useRaceScroll({
    races,
    isOpen,
    initialBehavior: initialScrollBehavior,
    hasNavigationButtons: isDesktop
  });

  const isResults = true;

  return (
    <Collapsible
      key={track.code}
      summary={
        <CollapsibleSummary
          trackCode={track.code}
          trackName={track.name}
          isResulted={track.isFinished}
          hasPromo={track.hasAboveTheLinePromo}
          qaLabel={qaLabel}
          module={module}
        />
      }
      isOpen={isOpen}
      onClick={() => onTrackClickHandler(track.code, isResults)}
      qaLabel={buildQaLabel([qaLabel, track.code])}
    >
      <ScrollButtonFloating
        data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
        onClick={() => handleScroll("prev")}
        disabled={!isPrevEnabled}
        isOpen={isOpen}
        position="start"
      >
        <Icon
          name="arrowLeft"
          size="s"
          lineColor="--fd-colors-component-button-tertiary-content-base"
        />
      </ScrollButtonFloating>

      <RaceListContainer
        ref={racesRef}
        data-qa-label={buildQaLabel([qaLabel, RACE, LIST])}
      >
        {isOpen &&
          races
            ?.map((race: PastRaceResults) => (
              <RaceResultCard
                key={race.track.code + race.number}
                dispatch={dispatch}
                race={race}
                loading={loading}
                isGrouped
                betsCounter={(racesWithBets?.[race.number] as number) ?? 0}
                onMyBetsClick={() =>
                  onMyBetsClick?.({
                    trackCode: race.track.code,
                    trackName: race.track.name,
                    raceNumber: race.number
                  })
                }
              />
            ))
            .reverse()}
      </RaceListContainer>

      <ScrollButtonFloating
        data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
        onClick={() => handleScroll("next")}
        disabled={!isNextEnabled}
        isOpen={isOpen}
        position="end"
      >
        <Icon
          name="arrowRight"
          size="s"
          lineColor="--fd-colors-component-button-tertiary-content-base"
        />
      </ScrollButtonFloating>
    </Collapsible>
  );
};

export default memo(CollapsibleWrapperResults);
