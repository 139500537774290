import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EmblaCarouselType } from "embla-carousel";
import { SectionHeader, useMediaQuery, breakpoints } from "@tvg/design-system";
import { Carousel } from "@tvg/design-system/web";
import { useUpcomingRaces } from "@urp/lib-racetracks";
import { EmptyMessage } from "@urp/tracks-builder";
import { isFilterApplied } from "@urp/tracks-builder/src/utils";
import { getUpcomingRacesInfo } from "@urp/store-selectors";
import { Container, EmptyMessageContainer } from "./styled-components";
import { UpcomingRacesProps } from "./types";
import NoUpcomingRaces from "./NoUpcomingRaces";
import renderRacesList from "../RacesList";
import LoadingMask from "../LoadingMask";

const UpcomingRaces = ({
  qaLabel = "upcoming-races",
  filterOptions,
  title,
  noUpcomingRacesMessages,
  showTitle = false
}: UpcomingRacesProps) => {
  const { maxResults, title: carouselTitle } =
    useSelector(getUpcomingRacesInfo);
  const { upcomingRaces, loading } = useUpcomingRaces({
    filterOptions,
    maxResults
  });
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const raceCards = renderRacesList(upcomingRaces, qaLabel);
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);

  // Reset carousel to the beginning
  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
  }, [JSON.stringify(filterOptions), emblaApi]);

  if (loading) {
    return <LoadingMask />;
  }

  if (!upcomingRaces?.length) {
    if (!noUpcomingRacesMessages) return null;

    if (isFilterApplied(filterOptions)) {
      return (
        <EmptyMessageContainer>
          <EmptyMessage />
        </EmptyMessageContainer>
      );
    }
    return (
      <NoUpcomingRaces
        title={noUpcomingRacesMessages.title}
        hint={noUpcomingRacesMessages.hint}
      />
    );
  }

  return (
    <Container data-qa-label={qaLabel}>
      {showTitle && title && <SectionHeader title={title} />}
      <Carousel
        containerSpace={{ pb: "space-4" }}
        edgeGap="--fd-space-space-3"
        items={raceCards}
        slidesToScroll="auto"
        dragFree={!isDesktop}
        qaLabel={qaLabel}
        slideSize={191}
        title={carouselTitle}
        displayHeader={isDesktop}
        onEmblaApi={setEmblaApi}
      />
    </Container>
  );
};

export default UpcomingRaces;
