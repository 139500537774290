import React, { memo } from "react";
import { useSelector } from "react-redux";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import { Props } from "./types";
import CollapsibleWrapper from "../CollapsibleWrapper";
import CollapsibleWrapperResults from "../CollapsibleWrapperResults";
import { getURPRacingResultsToggle } from "../../redux/selectors";
import { CONTAINER, LETTER_BLOCK, TITLE } from "../../utils/constants";
import SectionTitle from "../SectionTitle";

const TrackLetterBlock = ({
  letter,
  groupedRacesByTrack,
  onTrackClickHandler,
  onRaceCellClick,
  qaLabel = "",
  isResults = false,
  checkRacePromos,
  tracksWithBets,
  onMyBetsClick
}: Props) => {
  const isURPResultsEnabled = useSelector(getURPRacingResultsToggle);

  return (
    <div data-qa-label={buildQaLabel([qaLabel, LETTER_BLOCK, CONTAINER])}>
      <SectionTitle qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, TITLE])}>
        {letter.toUpperCase()}
      </SectionTitle>
      {groupedRacesByTrack.map((track) =>
        isURPResultsEnabled && isResults ? (
          <CollapsibleWrapperResults
            key={track.code}
            track={track}
            onTrackClickHandler={onTrackClickHandler}
            module={isResults ? Module.TRACKS_LIST : Module.TRACKS_AZ}
            racesWithBets={tracksWithBets?.[track.code]}
            onMyBetsClick={onMyBetsClick}
          />
        ) : (
          <CollapsibleWrapper
            key={track.code}
            track={track}
            isResults={isResults}
            onTrackClickHandler={onTrackClickHandler}
            module={isResults ? Module.TRACKS_LIST : Module.TRACKS_AZ}
            checkRacePromos={checkRacePromos}
            onRaceCellClick={onRaceCellClick}
          />
        )
      )}
    </div>
  );
};

export default memo(TrackLetterBlock);
