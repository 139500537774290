import styled from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--fd-colors-core-white);
  height: 326px;

  @media only screen and ${breakpoints.tablet.min.sm} {
    height: 676px;
  }
`;
