import React, { FocusEvent, useState } from "react";
import { format, parse } from "date-fns";
import {
  InputCalendar,
  InputSelect,
  SearchBar,
  InputField
} from "@tvg/design-system/web";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { ResultsSearchFormProps, FieldConfig } from "./types";
import { Field, FieldLabel, FieldsContainer } from "./styled-components";
import {
  DISPLAY_DATE_FORMAT,
  INTERNAL_DATE_FORMAT,
  WARNING_TOP_POSITION_OFFSET_DESKTOP,
  WARNING_TOP_POSITION_OFFSET_MOBILE
} from "../../constants";
import ModalSelection from "../ModalSelection";

const ResultsSearchForm: React.FC<ResultsSearchFormProps> = ({
  fieldsConfig,
  qaLabel
}) => {
  const isMobile = useMediaQuery(breakpoints.desktop.max.sm);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderField = (field: FieldConfig) => {
    switch (field.type) {
      case "date-picker": {
        // change to MM/dd/yyyy format - US date format
        const displayValue = field.value
          ? format(
              parse(field.value as string, INTERNAL_DATE_FORMAT, new Date()),
              DISPLAY_DATE_FORMAT
            )
          : "";
        const disabledDays = [
          { after: new Date() },
          field.availableDates
            ? (date: Date) =>
                !field.availableDates?.some((availableDate: string) =>
                  availableDate.includes(format(date, INTERNAL_DATE_FORMAT))
                )
            : undefined
        ];

        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <InputCalendar
              qaLabel={`${qaLabel}-${field.key}`}
              value={displayValue}
              onDayChange={(day) => {
                field.onChange(format(day, INTERNAL_DATE_FORMAT));
              }}
              disabledDays={disabledDays}
              isDisabled={
                field.availableDates && field.availableDates.length < 2
              }
              inputOverlayAlignment="right"
              isReadOnly
            />
          </Field>
        );
      }
      case "dropdown":
        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <InputSelect
              selectList={field.options || []}
              qaLabel={`${qaLabel}-${field.key}`}
              selectedValue={field.value as string}
              onChangeText={field.onChange}
              width={isMobile ? "100%" : "200px"}
            />
          </Field>
        );
      case "input-modal": {
        const fieldValue =
          field.options?.find((option) => option.value === field.value)
            ?.label || field.value;
        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <InputField
              qaLabel={`${qaLabel}-${field.key}`}
              value={fieldValue}
              onFocus={(event: FocusEvent<HTMLInputElement>) => {
                setIsModalOpen(true);
                setTimeout(() => event.target.blur());
              }}
              width={isMobile ? "100%" : "200px"}
            />
            <ModalSelection
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              selectedValue={field.value as string}
              setSelectedValue={field.onChange}
              // @ts-ignore
              options={field.options || []}
            />
          </Field>
        );
      }

      case "search": {
        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <SearchBar
              qaLabel={`${qaLabel}-${field.key}`}
              items={field.options || []}
              placeholder={field.placeholder || ""}
              searchTerm={field.value as string}
              setSearchTerm={field.onChange}
              warningMessages={field.warningMessages}
              handleOnClick={(itemValue) => {
                field.onSubmit?.(itemValue as number);
              }}
              isLoading={field.isLoading}
              filteredItemsAction={field.filteredItemsAction || (() => {})}
              warningTopPositionOffset={
                isMobile
                  ? WARNING_TOP_POSITION_OFFSET_MOBILE
                  : WARNING_TOP_POSITION_OFFSET_DESKTOP
              }
            />
          </Field>
        );
      }

      default:
        return null;
    }
  };

  return <FieldsContainer>{fieldsConfig.map(renderField)}</FieldsContainer>;
};

export default ResultsSearchForm;
