import styled from "styled-components";
import { Paragraph } from "@tvg/design-system/web/";

export const Container = styled.div`
  margin-top: 12px;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-left: 12px;
  text-align: start;
`;
