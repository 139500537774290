import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--fd-space-space-2);

  ${css`
    @media screen and ${breakpoints.desktop.max.sm} {
      gap: var(--fd-space-space-1);
    }
  `}
`;

export const FilterLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.default};
    font-family: ${theme.fonts.condensedRegular};
    font-size: ${theme.fontSizes.xs};
  `}
`;
