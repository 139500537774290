import React, { FC, useEffect, useState } from "react";
import { Icon } from "@tvg/design-system/web";
import { Paragraph } from "@tvg/design-system/";
import LoadingSpinner from "@tvg/design-system/src/components/loading";
import { getStreamSrc } from "@tvg/utils/pastPerformanceUtils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  CloseIconContainer,
  Loading,
  TitleContainer,
  VideoContainer
} from "./styled-components";
import { VideoPlayerProps } from "./types";
import { processVideoSrc } from "./utils";

const VideoPlayer: FC<VideoPlayerProps> = ({
  height,
  isAutoPlay,
  replayFile,
  title = "",
  width,
  qaLabel,
  openInlineMobile = false,
  onClose
}) => {
  const [replaySrc, setReplaySrc] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.document.documentElement.style.overflow =
      replayFile && openInlineMobile ? "hidden" : "";

    return () => {
      window.document.documentElement.style.overflow = "";
    };
  }, [replayFile, openInlineMobile]);

  useEffect(() => {
    if (replayFile) {
      setIsLoading(true);
      getStreamSrc(replayFile).then(
        ({ type, payload }: { type: string; payload: string }) => {
          if (typeof payload === "string" && type === "success") {
            const finalReplaySrc = processVideoSrc(payload);
            setIsLoading(false);
            setReplaySrc(finalReplaySrc);
          }
        }
      );
    }
  }, [replayFile]);

  if (isLoading) {
    return (
      <Loading
        width={width}
        height={height}
        openInlineMobile={openInlineMobile}
      >
        <LoadingSpinner size="xl" />
      </Loading>
    );
  }

  return (
    <VideoContainer
      data-qa-label={qaLabel}
      width={width}
      height={height}
      openInlineMobile={openInlineMobile}
      isXsell={isXSell()}
    >
      <TitleContainer title={title}>
        <Paragraph qaLabel="video-player-title" fontFamily="bold" fontSize={16}>
          {title}
        </Paragraph>
      </TitleContainer>
      <CloseIconContainer>
        <Icon size="s" name="close" onClick={onClose} />
      </CloseIconContainer>
      <iframe
        title="rcn-player-replay-video"
        allowFullScreen={false}
        src={replaySrc}
        width={`${width}px` || "100%"}
        height={`${height}px` || "100%"}
        allow={`fullscreen${isAutoPlay ? ";autoplay" : ""}`}
        id="rcn-player-replay-video"
      />
    </VideoContainer>
  );
};

export default VideoPlayer;
