import styled from "styled-components";

export const Container = styled.div<{ isDesktop: boolean }>`
  background-color: var(--fd-colors-core-white);
  ${({ isDesktop }) => !isDesktop && `margin-top: var(--fd-space-space-3);`}
`;

export const HeaderContainer = styled.div`
  position: relative;
`;

export const ToggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  display: flex;
  align-items: flex-start;
  padding: var(--fd-space-space-3);
`;
