import React, { FC, useMemo } from "react";

import {
  useResultsSearchByHorse,
  usePastRaces,
  setFilteredHorseNames
} from "@urp/lib-racetracks";
import PastResultsByYear from "../PastResultsByYear";
import HorseNotFound from "../../emptyCases/HorseNotFound";
import { ContentPlaceholder } from "./styled-components";
import { FieldConfig } from "../ResultsSearchForm/types";
import { useFieldsConfigChange } from "../../hooks/useFieldsConfigChange";

interface PastResultsHorseDeskProps {
  qaLabel: string;
  onFieldsConfigChange: (fieldsConfig: FieldConfig[]) => void;
}

const PastResultsHorseDesk: FC<PastResultsHorseDeskProps> = ({
  qaLabel,
  onFieldsConfigChange
}) => {
  const {
    searchText,
    setSearchText,
    selectedHorseOption,
    setSelectedHorseOption,
    horsesOptions,
    isLoading: isHorseSearchLoading
  } = useResultsSearchByHorse();

  useFieldsConfigChange(
    searchText,
    setSearchText,
    setSelectedHorseOption,
    horsesOptions,
    isHorseSearchLoading,
    onFieldsConfigChange,
    setFilteredHorseNames
  );

  const { isLoading: isPastRacesLoading, pastRaces } = usePastRaces({
    runnerId: selectedHorseOption?.value
      ? `${selectedHorseOption.value}`
      : undefined
  });

  const hasNotFoundHorse =
    !isHorseSearchLoading &&
    searchText.length > 3 &&
    (horsesOptions.length === 0 ||
      !horsesOptions.some((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      ));

  const memoizedTrackPastRaces = useMemo(
    () =>
      selectedHorseOption ? (
        <PastResultsByYear
          name={selectedHorseOption.title}
          pastRaces={pastRaces}
          isLoading={isPastRacesLoading}
          qaLabel={`${qaLabel}-horsePastRaces`}
        />
      ) : null,
    [selectedHorseOption, pastRaces, isPastRacesLoading, qaLabel]
  );

  if (hasNotFoundHorse) {
    return <HorseNotFound name={searchText} />;
  }

  if (!selectedHorseOption || horsesOptions.length === 0) {
    return <ContentPlaceholder />;
  }

  return memoizedTrackPastRaces;
};

export default PastResultsHorseDesk;
