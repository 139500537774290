import React, { FC } from "react";

import { Paragraph, breakpoints, useMediaQuery } from "@tvg/design-system";
import { IconWithBackground } from "@tvg/design-system/web";
import {
  HintContainer,
  IconContainer,
  NoUpcomingRacesContainer
} from "./styled-components";
import { NoUpcomingRacesProps } from "./types";

const NoUpcomingRaces: FC<NoUpcomingRacesProps> = ({ title, hint }) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);

  return (
    <NoUpcomingRacesContainer>
      <Paragraph
        qaLabel="no-upcomingRaces-title"
        fontFamily="bold"
        fontSize={isDesktop ? 18 : 14}
      >
        {title}
      </Paragraph>
      {isDesktop ? (
        <HintContainer>
          <IconContainer>
            <IconWithBackground
              mainIcon="filters"
              badgeIcon="info"
              override={{
                mainIcon: {
                  size: "s"
                }
              }}
            />
          </IconContainer>
          <Paragraph qaLabel="no-upcomingRaces-hint" fontFamily="bold">
            {hint}
          </Paragraph>
        </HintContainer>
      ) : (
        <Paragraph qaLabel="no-upcomingRaces-hint">{hint}</Paragraph>
      )}
    </NoUpcomingRacesContainer>
  );
};

export default NoUpcomingRaces;
