import React, { useState } from "react";
import { Button } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { sendAllFiltersModalOpenedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { ACTION, FILTER } from "../../../utils/constants";
import Drawer from "../Drawer";
import { Props } from "./types";

const DrawerWrapper: React.FC<Props> = ({ qaLabel, title, currentTab }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const onOpenDrawer = () => {
    sendAllFiltersModalOpenedAnalyticEvt({
      module: "tracks-filter",
      activeTab: currentTab
    });
    setShowDrawer(!showDrawer);
  };

  const onCloseDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <>
      <Button
        qaLabel={buildQaLabel([qaLabel, FILTER, ACTION])}
        variant="secondary"
        icon="filters"
        onClick={onOpenDrawer}
      >
        Filters
      </Button>

      {showDrawer && (
        <Drawer
          isOpen={showDrawer}
          onClose={onCloseDrawer}
          title={title}
          qaLabel={buildQaLabel([qaLabel, FILTER])}
          currentTab={currentTab}
        />
      )}
    </>
  );
};

export default DrawerWrapper;
