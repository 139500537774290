import React, { memo } from "react";
import { Icon, LinkCard, LinkCardList } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import SectionTitle from "../SectionTitle";
import type { Props } from "./types";
import FavoriteIcon from "../FavoriteIcon";
import { LINK_ITEM, LOCATION } from "../../utils/constants";
import { IconContainer } from "./styled-components";

const TracksInformationLocation = ({
  title,
  dictionary,
  onTrackClick,
  isFavorite,
  toggleFavorite
}: Props) => (
  <div key={title}>
    <SectionTitle>{title}</SectionTitle>
    <LinkCardList qaLabel={buildQaLabel([LINK_ITEM, LOCATION, title])}>
      {get(dictionary, title, []).map((track) => (
        <LinkCard
          isLinkItem
          key={track.code}
          qaLabel={track.name}
          title={track.name}
          startContent={
            <FavoriteIcon
              isFavorite={isFavorite(track.code)}
              toggleFavorite={() => toggleFavorite(track.code, track.name)}
              trackName={track.name}
            />
          }
          endContent={
            <IconContainer>
              <Icon
                name="chevronRight"
                size="s"
                lineColor="--fd-colors-content-default"
              />
            </IconContainer>
          }
          onPress={() => onTrackClick(track)}
        />
      ))}
    </LinkCardList>
  </div>
);

export default memo(TracksInformationLocation);
